import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M6076 10505 c-105 -11 -142 -19 -151 -31 -9 -13 -16 -14 -32 -6 -13
7 -31 8 -49 2 -16 -5 -75 -18 -132 -30 -101 -21 -299 -80 -334 -100 -10 -6
-23 -10 -29 -10 -16 0 -246 -103 -319 -143 -36 -19 -84 -45 -107 -57 -30 -15
-40 -26 -37 -38 5 -14 4 -14 -4 -3 -11 16 0 22 -162 -84 -41 -27 -77 -52 -80
-55 -3 -3 -34 -27 -70 -55 -64 -49 -86 -67 -184 -155 -80 -71 -201 -197 -282
-293 -58 -69 -73 -93 -69 -110 5 -17 4 -19 -4 -7 -5 8 -12 12 -14 10 -7 -7
-61 -85 -119 -170 -47 -69 -178 -296 -178 -307 0 -3 -13 -31 -29 -63 -59 -116
-151 -358 -151 -398 0 -13 -5 -33 -11 -44 -31 -57 -92 -358 -109 -533 -11
-122 -14 -380 -5 -520 7 -115 36 -325 45 -325 4 0 8 -12 8 -27 2 -77 16 -134
88 -358 87 -271 185 -473 342 -713 30 -46 60 -89 67 -95 6 -7 20 -25 30 -42
96 -151 343 -407 535 -555 18 -14 40 -32 49 -41 21 -22 238 -169 259 -176 10
-3 24 -12 32 -19 8 -7 50 -30 94 -53 43 -22 83 -45 90 -50 22 -19 313 -140
386 -161 41 -12 84 -26 95 -30 28 -13 194 -55 290 -74 164 -33 316 -48 530
-52 192 -3 245 -1 414 21 107 14 211 30 230 35 20 6 70 17 111 26 68 13 97 22
155 44 11 4 61 20 110 35 50 15 99 33 110 39 11 7 33 16 49 20 34 8 271 123
341 166 28 16 59 34 70 40 27 14 141 85 157 99 34 29 91 71 97 71 3 0 23 15
43 33 20 17 58 50 84 72 106 89 161 140 201 184 171 186 184 201 230 261 23
30 49 63 57 72 8 9 22 31 32 49 9 18 25 41 35 52 54 58 231 388 302 562 84
207 201 682 184 747 -4 12 -2 27 4 34 15 18 26 365 17 518 -10 167 -39 362
-78 523 -32 128 -115 378 -157 473 -41 92 -77 165 -83 170 -3 3 -25 43 -49 90
-24 47 -78 136 -119 199 -76 116 -84 128 -114 162 -10 10 -18 22 -18 26 0 17
-44 51 -59 46 -14 -4 -14 -3 -1 6 12 9 11 14 -11 38 -14 15 -65 73 -115 127
-49 54 -123 128 -164 163 -41 35 -86 74 -100 87 -30 27 -158 127 -181 141 -9
6 -54 35 -100 67 -113 76 -149 96 -324 182 -306 150 -588 238 -910 281 -168
23 -551 28 -729 10z m-1 -29 c-32 -14 -41 -13 -30 3 3 6 18 11 33 11 l27 -1
-30 -13z m755 -58 c0 -5 -7 -3 -15 6 -8 8 -15 18 -15 22 0 3 7 1 15 -6 8 -7
15 -17 15 -22z m-770 -22 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m36 -121 c1 -14 -2 -22 -7 -19 -11 6 -12 44 -1 44 4 0 8
-11 8 -25z m1288 16 c4 -5 -3 -7 -14 -4 -23 6 -26 13 -6 13 8 0 17 -4 20 -9z
m-1994 -46 c-7 -8 -22 -15 -34 -14 -18 1 -17 3 7 12 15 6 27 15 27 21 0 5 3 6
6 3 4 -4 1 -13 -6 -22z m195 -4 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6
21 15 7z m1700 -51 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2
0 7 -4 10 -10z m-676 -79 c9 -6 10 -11 2 -15 -13 -8 -31 1 -31 15 0 11 11 11
29 0z m-1548 -64 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z
m219 19 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m2397 -18
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-912 -28 c-3 -5 -14
-10 -23 -10 -15 0 -15 2 -2 10 20 13 33 13 25 0z m-1095 -60 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1750 0 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1930 -4 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2657 -83 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-1017 3 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m510 0 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-890 -60 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-1363 -42
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1183 -3 c0 -5 -10 -11
-22 -14 -22 -6 -22 -6 -4 8 23 17 26 18 26 6z m1538 -6 c51 -8 104 -12 117 -9
54 13 101 -8 153 -70 24 -29 25 -73 3 -145 -11 -38 -21 -80 -21 -93 0 -12 -7
-48 -16 -78 -13 -50 -26 -115 -54 -295 -13 -81 -40 -262 -46 -305 -2 -19 -10
-82 -18 -140 -8 -58 -22 -163 -31 -235 -19 -153 -57 -394 -92 -590 -13 -77
-27 -156 -29 -175 -25 -183 -134 -597 -194 -735 -9 -22 -22 -56 -29 -75 -6
-19 -27 -66 -46 -104 -19 -39 -35 -75 -35 -82 0 -6 26 -21 58 -34 277 -109
478 -169 683 -204 183 -32 450 -15 554 35 51 25 103 -34 75 -86 -23 -43 -166
-75 -366 -82 -175 -6 -255 3 -459 54 -116 28 -424 131 -536 178 l-78 33 -26
-38 c-73 -106 -208 -197 -363 -245 -58 -19 -224 -28 -256 -15 -12 5 -32 11
-46 14 -14 3 -50 20 -81 39 l-56 34 -42 -43 c-25 -26 -39 -49 -35 -55 4 -8 3
-9 -5 -5 -6 4 -25 -2 -41 -14 -24 -17 -28 -25 -20 -35 9 -11 7 -12 -9 -8 -14
4 -42 -8 -84 -35 -34 -23 -93 -55 -132 -72 -38 -17 -78 -36 -87 -41 -10 -5
-25 -9 -33 -9 -8 0 -15 -4 -15 -9 0 -9 -34 -19 -132 -40 -20 -5 -49 -16 -64
-26 -14 -9 -23 -12 -20 -6 4 6 6 11 4 11 -2 0 -52 -9 -113 -20 -60 -11 -138
-20 -173 -20 -35 0 -71 -4 -80 -8 -15 -7 -231 -2 -459 12 -298 17 -650 108
-845 218 l-48 26 -47 -22 c-70 -34 -110 -44 -187 -49 -162 -11 -293 35 -399
139 -101 99 -152 187 -193 334 -29 102 -32 258 -9 371 15 72 76 214 115 269
12 17 18 30 15 30 -20 0 163 161 240 212 104 68 140 73 164 24 18 -38 4 -66
-45 -91 -102 -51 -204 -139 -264 -225 -92 -135 -123 -236 -123 -400 0 -100 4
-126 27 -195 16 -44 39 -100 53 -124 40 -69 144 -168 210 -199 52 -24 69 -27
163 -26 80 0 113 4 138 17 27 15 31 21 22 36 -5 11 -14 21 -19 23 -18 6 -97
135 -119 192 -31 80 -54 236 -47 305 17 159 58 264 147 375 58 72 96 101 197
151 149 74 314 50 423 -62 57 -59 80 -120 79 -215 0 -80 -28 -203 -58 -262 -8
-15 -13 -37 -10 -47 3 -10 1 -17 -4 -16 -8 2 -89 -128 -89 -144 0 -5 -3 -9 -7
-9 -5 0 -21 -21 -38 -48 -16 -26 -38 -56 -48 -67 -9 -10 -15 -25 -13 -32 3 -7
2 -12 -3 -10 -4 1 -43 -33 -87 -76 l-79 -78 25 -19 c44 -34 182 -94 245 -106
140 -28 233 -35 395 -30 96 3 195 10 219 16 24 6 71 13 105 15 108 8 275 50
423 106 101 39 264 119 294 145 8 8 19 14 23 14 5 0 20 10 35 23 15 12 40 31
57 42 16 11 77 68 135 126 94 95 112 119 157 211 32 65 57 133 68 183 20 96
20 232 0 279 -8 19 -12 37 -9 39 8 9 -72 161 -115 218 -23 30 -60 76 -84 103
l-44 48 -69 -34 c-159 -80 -260 -100 -509 -102 l-169 -1 -3 -35 c-1 -19 -5
-46 -8 -60 -3 -14 -13 -61 -22 -105 -8 -44 -25 -118 -36 -165 -11 -47 -22 -98
-25 -115 -2 -16 -8 -39 -13 -50 -21 -50 -71 -216 -70 -232 3 -29 0 -39 -8 -26
-5 8 -20 -12 -41 -57 -19 -39 -38 -70 -42 -70 -5 0 -9 -7 -9 -15 0 -8 -8 -24
-17 -34 -10 -11 -38 -44 -63 -74 -39 -47 -147 -130 -219 -167 -28 -14 -141
-46 -196 -54 -21 -4 -43 -12 -47 -19 -6 -9 -8 -9 -8 1 0 8 -17 12 -49 12 -55
0 -81 17 -81 54 0 32 28 54 80 61 60 9 110 25 110 35 0 4 11 11 24 14 63 16
175 191 242 376 53 148 144 540 144 620 0 18 4 47 10 64 5 17 19 103 30 191
12 88 24 173 27 189 7 41 -12 25 -37 -30 -21 -46 -26 -52 -94 -133 -46 -54
-179 -148 -253 -179 -166 -70 -412 -68 -553 3 -29 15 -58 24 -66 21 -8 -3 -13
0 -11 7 1 7 -5 12 -13 12 -14 0 -40 18 -40 27 0 11 -117 122 -125 120 -5 -2
-7 -1 -3 3 4 4 -13 47 -38 95 -24 48 -44 93 -44 99 0 6 -4 19 -10 29 -14 26
-40 190 -40 256 0 44 -4 62 -17 72 -15 12 -16 13 -2 8 14 -4 18 6 24 68 23
241 127 484 274 640 101 108 275 227 406 278 11 4 43 17 70 28 171 68 437 111
742 119 194 5 223 8 228 22 3 9 24 29 47 44 37 25 51 28 100 25 67 -5 122 -41
145 -97 8 -20 16 -37 17 -39 2 -1 44 -13 94 -26 76 -20 92 -21 92 -9 0 8 5 11
11 8 6 -4 8 -13 6 -20 -3 -7 18 -23 56 -41 106 -50 233 -141 302 -217 67 -75
143 -194 164 -259 7 -21 16 -38 20 -38 4 0 7 -8 8 -17 1 -10 9 -54 17 -98 19
-94 21 -252 5 -327 -9 -39 -9 -53 0 -56 15 -5 14 -22 -2 -22 -16 0 -41 -75
-31 -93 4 -6 4 -9 -1 -5 -4 4 -28 -32 -52 -80 -24 -48 -57 -104 -74 -124 -16
-20 -29 -39 -29 -43 0 -3 -36 -41 -79 -84 l-79 -79 81 -65 c153 -121 260 -242
335 -377 53 -94 92 -227 95 -324 l2 -49 -53 -3 c-163 -8 -264 -151 -249 -350
6 -75 37 -153 75 -187 20 -18 21 -18 49 17 15 20 31 36 36 36 4 0 31 -11 59
-25 72 -35 138 -41 220 -20 81 20 139 47 180 83 35 30 108 138 108 159 0 7
-21 23 -47 34 -41 19 -156 72 -243 114 -35 17 -119 37 -130 30 -4 -3 -11 -24
-15 -48 -16 -106 -106 -317 -134 -317 -4 0 -20 23 -36 51 -25 48 -27 57 -21
125 11 125 46 177 129 193 25 4 49 6 51 3 8 -7 26 45 26 74 0 27 14 30 74 13
31 -8 41 -8 47 3 7 10 9 10 9 -2 0 -9 16 -21 38 -29 46 -15 129 -49 162 -66
28 -15 132 -55 141 -55 3 0 16 35 28 78 12 42 28 100 36 127 21 74 76 355 74
374 -2 9 0 25 4 36 10 30 53 384 71 575 7 80 64 500 86 630 19 120 23 180 9
180 -9 0 -8 4 3 13 14 11 40 123 53 229 5 45 20 110 56 252 16 66 28 128 26
138 -3 10 -1 18 3 18 5 0 21 39 35 88 14 48 35 109 45 136 11 27 18 51 16 53
-1 2 -46 7 -99 12 -174 17 -376 -16 -502 -80 -96 -50 -215 -142 -215 -167 0
-5 -6 -12 -14 -15 -16 -6 -68 -112 -88 -177 -18 -63 -17 -267 1 -300 8 -14 15
-32 16 -40 2 -23 29 -80 37 -80 5 0 8 -7 8 -15 0 -18 16 -20 24 -2 4 6 4 2 2
-10 -5 -26 47 -77 98 -98 46 -19 138 -18 188 1 55 21 121 83 114 107 -2 10 1
17 10 17 8 0 14 2 14 4 0 2 4 27 10 55 7 39 6 65 -5 108 -18 68 -27 82 -99
156 -60 61 -61 63 -47 100 24 62 79 40 171 -70 78 -92 108 -231 76 -351 -45
-167 -193 -271 -374 -261 -211 12 -372 259 -366 565 2 72 8 109 34 185 18 51
41 106 51 121 11 14 19 30 19 35 0 13 111 129 143 148 15 10 27 23 27 29 0 7
6 13 13 13 6 0 48 18 92 40 148 72 274 101 455 102 63 1 157 -6 208 -14z
m-994 -31 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5 0 12 -7 15 -16z m-124
-25 c0 -6 -4 -7 -10 -4 -5 3 -21 -6 -35 -21 -14 -15 -25 -24 -25 -20 0 4 12
21 27 36 25 27 43 31 43 9z m30 1 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m-1734 -15 c-11 -8 -22 -12 -25 -9 -6 6 19 24
34 24 6 -1 1 -7 -9 -15z m3204 -90 c7 -8 9 -15 6 -15 -4 0 -14 7 -22 15 -9 8
-11 15 -6 15 5 0 15 -7 22 -15z m-3930 -55 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m2500 3 c-1 -13 -29 -59 -30 -48 0
21 13 55 21 55 5 0 9 -3 9 -7z m-497 -38 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2
15 9 16 15 13 8 -4z m197 11 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16
21 21 21 13z m-165 -36 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3
0 8 -4 11 -10z m-1015 -15 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6
-2 -15z m1140 -13 c0 -5 -7 -9 -15 -9 -8 0 -15 4 -15 9 0 4 7 8 15 8 8 0 15
-4 15 -8z m973 -39 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m-648 -53 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m485 -15
c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m-3457 -20 c-3 -8
-9 -15 -14 -15 -10 0 -12 24 -3 33 11 11 24 -3 17 -18z m4452 15 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-1525 -64 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m277 -18 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m443 12 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m70 -50 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-515 -40 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m562 -6 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m683 -9 c0 -8 -4 -15 -10 -15 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-179 -22 c13 -16 12 -17 -3
-4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-981 -28 c0 -5 -5 -3 -10 5 -5 8 -10
20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m1385 14 c4 -6 14 -8 22 -5 8 3
11 1 8 -4 -9 -14 -24 -12 -41 5 -9 8 -11 15 -5 15 5 0 13 -5 16 -11z m-151
-164 c-4 -8 -13 -15 -20 -15 -12 0 -12 3 -2 15 17 19 29 19 22 0z m-714 -36
c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z
m-335 -29 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4
15 -10z m-555 -20 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z m1910 -20 c-8 -5 -16 -8 -17 -6 -2 2 -1 11 3 20 5 13 10 14 18 6 8
-8 7 -13 -4 -20z m-4783 -62 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m4274 -91 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-611
-41 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-3743 -38 c6 17 23 15 23 -3 0 -21 -46 -19 -54 1 -6 15 -4 15 10 3 13 -11 18
-11 21 -1z m4438 -27 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15
-4z m-1351 -17 c6 -15 4 -15 -11 -3 -10 7 -20 15 -22 16 -2 2 3 3 11 3 9 0 19
-7 22 -16z m601 -4 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m650 -50 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m-925 -45 c0 -19 -4 -35 -8 -35 -4 0 -6 16 -4 35 2 19 6 35 8 35
2 0 4 -16 4 -35z m1490 15 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m-60 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m-1143 -42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m1148 11 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17
-11z m165 -2 c-27 -15 -36 -14 -25 2 3 6 16 11 28 11 21 0 21 -1 -3 -13z
m-1310 -72 c-6 -8 -9 -19 -5 -25 3 -5 1 -10 -4 -10 -12 0 -15 33 -4 43 13 14
25 7 13 -8z m1387 -47 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-5277 -8 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m3297 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1073 12
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-473 -11 c-14 -8 -22 -8 -30 0 -8 8 -3 11 19 11 25 -1 27 -2 11 -11z m1325
-54 c3 -14 1 -28 -3 -31 -5 -3 -9 9 -9 25 0 38 6 40 12 6z m-688 -19 c-6 -17
-52 -42 -60 -33 -4 4 5 11 20 16 14 6 26 15 26 21 0 5 4 10 10 10 5 0 7 -6 4
-14z m-4446 -37 c-3 -8 -7 -3 -11 10 -4 17 -3 21 5 13 5 -5 8 -16 6 -23z
m4622 11 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-5026 -25 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7
10 -15z m3483 9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1386
-9 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-1043 1 c0 -2 -7
-7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-420 -16 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m280 6 c0 -2 -7 -9
-15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z m-3460 -26 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m3630 -10 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-419 -17 c13
-16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m706 -79 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m583 -8 c0 -2 -7 -7 -16 -10 -8 -3
-12 -2 -9 4 6 10 25 14 25 6z m-4658 -58 c-9 -9 -12 -7 -12 12 0 19 3 21 12
12 9 -9 9 -15 0 -24z m3725 -55 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m-267 17 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m1280 6 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25
6z m310 -16 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m30 -10 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m-4760 -31 c0 -5 -7 -9 -15 -9 -9 0 -12 6 -9 15 6 15 24 11 24 -6z
m3420 -9 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m120 -39 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10
-4 10 -9z m-624 -31 c0 -24 -3 -24 -22 -6 -11 11 -11 16 -1 19 21 9 23 8 23
-13z m81 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-3016 -27
c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m3149 -23 c0 -22 -16
-17 -17 6 -1 11 2 18 8 15 5 -4 9 -13 9 -21z m1220 -98 c0 -2 -7 -6 -15 -10
-8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m130 -20 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-4910 -16 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m3635 -49 c3 -5
1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-3165 -61 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m3580 6 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-3639 -46 c6 0
7 -5 4 -10 -12 -19 -34 9 -27 33 l7 22 3 -22 c2 -13 8 -23 13 -23z m4929 10
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-4741
-4 c-12 -10 -60 -16 -54 -6 3 5 18 10 32 10 15 0 24 -2 22 -4z m1224 -51 c-3
-9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m2134 -62 c-3 -10 -5 -4
-5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-397 -63 c0 -22 -34 -28 -46 -8 -5 7
-2 9 9 5 9 -3 18 0 21 9 8 20 16 17 16 -6z m-2962 -38 c2 -15 0 -23 -4 -19 -5
4 -10 20 -12 35 -2 15 0 23 4 19 5 -4 10 -20 12 -35z m5242 -13 c0 -6 -4 -7
-10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-4323 -21 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1128 12 c-3 -5 -11 -10 -16
-10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m1970 -10 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2315 -20 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m195 -20 c3
-5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-708 -16
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1287 -110 c3 -8 2 -12
-4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-903 -41 c-12 -20 -14 -14 -5 12
4 9 9 14 11 11 3 -2 0 -13 -6 -23z m3229 -23 c-16 -11 -40 -13 -40 -5 0 8 44
24 50 18 2 -2 -2 -8 -10 -13z m-2304 -116 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1
22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z m-449 -6 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z m-1022 -8 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m1530 -30 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19
12 23 12 15 0z m-107 -7 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18
-5z m62 -7 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z m20
-56 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m895 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-2422 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-113 -68 c0
-5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m3985
15 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-4010 -20 c-3
-5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m75 -74
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m1447 -12 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m959 -9 c9
-8 11 -15 6 -15 -5 0 -15 7 -22 15 -7 8 -9 15 -6 15 4 0 14 -7 22 -15z m-2393
-65 c-3 -11 -10 -23 -16 -27 -7 -4 -8 0 -4 11 4 10 7 22 7 27 0 5 4 9 9 9 5 0
7 -9 4 -20z m-216 -92 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m2463 -12 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m-1293 -48 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m123
-2 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-1290 -16 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m690 -9 c0 -5 -7 -12 -16 -15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z
m1360 -21 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m-1613 -72 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m457 13
c4 -5 -3 -7 -14 -4 -23 6 -26 13 -6 13 8 0 17 -4 20 -9z m351 -21 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-245 -10 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-58 -32 c-7 -7
-12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m1145 -30 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-1020 6 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-547 -65 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16
0 6 5 7 10 4 6 -3 10 -11 10 -16z m1424 6 c-4 -8 -11 -15 -16 -15 -6 0 -5 6 2
15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m-1309 -45 c3 -5 1 -10 -4 -10 -6 0 -11
5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-158 -6 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-1410 -41 c-9 -9 -20 -13 -24 -9 -4 4 -1 11 7 16
25 16 35 11 17 -7z m2683 -13 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m-1945 -58 c-8 -8 -25 10 -19 20 4 6 9 5 15 -3 4
-7 6 -15 4 -17z m1948 3 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12 3
-20z m237 5 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m-2393 -76 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m2463
-19 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z
m170 5 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-1369 -107 c13 -17 13 -17 -6 -3 -11 8 -22 16 -24 17 -2 2 0 3 6 3 6 0 17 -8
24 -17z m449 7 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4
4 -10z m2632 -22 c-11 -11 -32 -3 -32 12 0 4 9 6 21 3 13 -4 17 -9 11 -15z
m-3052 -28 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5
-5 -10z m483 -20 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7
-9 4 -20z m134 -17 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m-1474 20 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m1424 -19
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-517 -28 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m427 -18 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1793 8 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-2333 -38 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m805 -20 c-7 -7 -12 -8 -12 -2 0 14 12
26 19 19 2 -3 -1 -11 -7 -17z m-1348 -13 c3 -8 1 -15 -4 -15 -6 0 -10 5 -10
11 0 6 -7 8 -16 5 -8 -3 -12 -2 -9 4 9 15 32 12 39 -5z m606 -5 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m290 -30 c-8 -5
-19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5 -5 -10z m-1770 -4
c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m1587 -38 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1553 -10 c0 -6 -7 -5 -15 2 -8 7
-15 17 -15 22 0 6 7 5 15 -2 8 -7 15 -17 15 -22z m-1499 -1 c-10 -9 -11 -8 -5
6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m1059 3 c0 -5 -7 -10 -15 -10 -8 0 -15
5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-67 -20 c-4 -14 0 -20 11 -20 14
0 15 -2 4 -14 -9 -10 -14 -11 -17 -3 -2 7 -8 15 -14 18 -12 8 -2 39 12 39 5 0
7 -9 4 -20z m-731 -22 c-11 -11 -32 -3 -32 12 0 4 9 6 21 3 13 -4 17 -9 11
-15z m903 2 c-27 -12 -35 -12 -35 0 0 6 12 10 28 9 24 0 25 -1 7 -9z m-235 -4
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-870 -26 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m1217 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m633
-3 c11 -13 10 -14 -4 -9 -9 3 -16 10 -16 15 0 13 6 11 20 -6z m-2060 -30 c-6
-8 -9 -19 -5 -25 3 -5 1 -10 -4 -10 -15 0 -14 37 2 43 19 8 20 8 7 -8z m907
-7 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m386 -3 c-3 -9 -8
-14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-2353 -15 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1320 -14 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-845 -16 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m589 -10 c-10
-4 -25 -5 -32 -2 -22 8 6 21 31 14 20 -5 20 -6 1 -12z m423 -2 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m58 12 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-295 -40 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1142 -25 c3 -14 1 -25 -3 -25
-5 0 -9 11 -9 25 0 14 2 25 4 25 2 0 6 -11 8 -25z m-692 -20 c-7 -9 -15 -13
-17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m-30 -25 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m210 -5 c0 -8 -2 -15 -4 -15
-2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-1630 -5 c0 -5 -7 -10
-16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m85 0 c3 -5 -1 -10
-10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m1015 -20 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1050 -62 c0
-6 -6 -5 -15 2 -8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11 15 -16z m185 12
c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m1142 -42 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-970 -4 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m2254 -12 c-7 -2 -18 1 -23 6 -8 8 -4 9 13 5 13
-4 18 -8 10 -11z m-1874 -14 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m158 12 c-3 -5 -12 -7 -20 -3 -21 7 -19 13 6 13 11 0 18 -4 14 -10z m372
4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-197 -49 c-7 -9 -15
-13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m-533 -11 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m2223 -18 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1
-15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m-2368 -58 c-8 -8 -9 -4 -5 13 4 13 8
18 11 10 2 -7 -1 -18 -6 -23z m948 2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
6 8 10 11 10 2 0 4 -4 4 -10z m330 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m-1190 -90 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m490 -50 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m310 -125 c-7 -9 -15 -13 -19 -10
-3 3 1 10 9 15 21 14 24 12 10 -5z m-190 -105 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M5650 9254 c-159 -18 -273 -53 -441 -133 -135 -66 -209 -111 -294
-179 -16 -13 -42 -33 -58 -45 -69 -55 -209 -208 -254 -281 -95 -149 -130 -257
-140 -423 -5 -92 -3 -125 12 -180 10 -38 21 -72 25 -78 4 -5 10 -22 14 -36 9
-39 106 -158 162 -200 83 -62 204 -96 288 -80 91 17 164 58 239 136 131 134
182 272 156 422 -13 73 -62 156 -114 191 -75 52 -169 22 -234 -72 -42 -60 -90
-76 -118 -38 -25 32 -15 65 41 128 136 152 297 166 424 36 55 -57 87 -113 102
-182 17 -73 20 -84 24 -79 2 2 11 47 20 99 9 52 23 127 31 165 8 39 22 99 29
135 13 60 42 173 80 310 24 91 105 340 117 362 6 11 7 23 2 27 -4 4 -55 2
-113 -5z m-785 -424 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m145 -1140 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z"/>
<path d="M6107 9173 c-47 -109 -139 -402 -131 -415 3 -5 1 -14 -4 -21 -14 -17
-48 -138 -62 -222 -7 -38 -18 -90 -26 -115 -13 -45 -19 -74 -54 -270 -47 -266
-52 -312 -38 -321 10 -7 10 -11 -4 -21 -9 -8 -19 -35 -23 -67 l-7 -53 53 7
c80 10 128 26 132 44 4 14 5 14 6 -1 1 -14 6 -15 36 -7 52 15 163 66 220 102
50 31 175 145 175 159 0 4 11 19 24 33 33 36 93 152 122 237 36 107 51 264 35
374 -13 91 -33 166 -56 219 -8 17 -18 42 -24 57 -25 66 -131 194 -191 231 -19
11 -37 24 -40 28 -8 11 -102 59 -115 59 -7 0 -19 -17 -28 -37z m343 -267 c0
-2 -13 -6 -29 -8 -17 -2 -28 -1 -25 4 5 8 54 12 54 4z m-205 -356 c-3 -5 -12
-10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-128 -166 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m50 -370 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z"/>
<path d="M4603 7131 c-33 -22 -78 -60 -99 -84 -111 -126 -160 -348 -119 -541
8 -37 19 -74 25 -81 5 -6 7 -15 3 -18 -3 -4 -2 -7 4 -7 6 0 13 -12 17 -27 3
-16 13 -37 21 -47 15 -19 16 -19 46 10 56 52 194 227 236 299 69 117 112 223
128 318 8 52 -17 136 -52 169 -68 63 -123 65 -210 9z m142 -91 c-3 -5 -11 -10
-16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-190 -180 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m175 -30 c-6
-11 -13 -20 -16 -20 -2 0 0 9 6 20 6 11 13 20 16 20 2 0 0 -9 -6 -20z m-203
-62 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m27 -233 c-4 -8 -11
-12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z"/>
<path d="M5018 4281 c-55 -18 -89 -47 -113 -95 -25 -50 -25 -50 -25 -358 0
-324 3 -352 48 -401 64 -71 220 -81 300 -21 19 15 44 48 56 73 20 44 21 61 21
351 0 263 -3 310 -17 340 -21 44 -50 75 -90 96 -37 19 -140 28 -180 15z m122
-141 c19 -19 20 -33 20 -308 0 -328 -1 -332 -70 -332 -69 0 -70 5 -70 323 0
274 4 309 34 329 21 14 66 8 86 -12z"/>
<path d="M9265 4285 c-5 -2 -22 -6 -37 -9 -110 -24 -163 -207 -98 -341 12 -25
66 -90 124 -148 87 -88 105 -111 115 -151 14 -57 5 -113 -20 -126 -25 -14 -72
-12 -94 4 -13 9 -20 30 -23 70 l-4 56 -64 0 -64 0 0 -52 c0 -107 44 -185 118
-209 105 -35 223 0 270 79 24 42 27 57 27 137 -1 116 -23 160 -135 270 -105
103 -133 146 -134 206 -1 56 23 89 66 89 43 0 68 -32 68 -85 l0 -45 71 0 72 0
-6 60 c-11 100 -51 159 -124 186 -35 12 -104 17 -128 9z"/>
<path d="M2017 4273 c-4 -3 -7 -206 -7 -450 l0 -444 153 3 c181 4 212 14 255
87 25 43 27 54 27 161 0 103 -3 119 -24 156 -13 23 -35 47 -48 53 l-25 11 36
36 c41 41 50 82 45 203 -3 61 -9 86 -25 107 -31 42 -67 65 -115 75 -53 10
-263 12 -272 2z m248 -148 c22 -21 25 -33 25 -95 0 -62 -3 -74 -25 -95 -18
-19 -35 -25 -70 -25 l-45 0 0 113 c0 63 3 117 7 120 3 4 24 7 45 7 28 0 46 -7
63 -25z m14 -364 c35 -35 38 -203 4 -237 -9 -9 -36 -14 -74 -14 l-59 0 0 135
0 135 55 0 c40 0 60 -5 74 -19z"/>
<path d="M2787 4274 c-4 -4 -7 -207 -7 -451 l0 -443 195 0 195 0 0 65 0 64
-122 3 -123 3 -3 110 c-4 162 -9 154 101 157 l92 3 3 62 3 62 -98 3 -98 3 -1
100 c-1 146 -12 135 128 135 l119 0 -3 63 -3 62 -186 3 c-102 1 -188 -1 -192
-4z"/>
<path d="M3507 4273 c-4 -3 -7 -206 -7 -450 l0 -443 190 0 191 0 -3 63 -3 62
-117 3 -118 3 0 384 0 385 -63 0 c-35 0 -67 -3 -70 -7z"/>
<path d="M4200 3830 l0 -450 185 0 185 0 0 65 0 65 -115 0 -115 0 -2 383 -3
382 -67 3 -68 3 0 -451z"/>
<path d="M6117 4274 c-4 -4 -7 -207 -7 -451 l0 -443 190 0 191 0 -3 63 -3 62
-112 3 -113 3 -2 382 -3 382 -65 3 c-36 2 -69 0 -73 -4z"/>
<path d="M6921 4258 c-4 -13 -13 -59 -20 -103 -7 -44 -37 -230 -67 -413 -30
-183 -54 -340 -54 -348 0 -11 15 -14 64 -14 l64 0 12 85 12 85 78 0 c88 0 84
4 95 -85 12 -88 8 -85 86 -85 38 0 69 1 69 3 0 1 -18 108 -39 237 -21 129 -54
327 -72 440 -18 113 -38 208 -44 212 -5 4 -47 8 -93 8 -77 0 -83 -2 -91 -22z
m124 -363 c34 -233 37 -215 -35 -215 l-61 0 5 33 c3 17 15 100 26 182 23 164
31 208 37 185 1 -8 14 -91 28 -185z"/>
<path d="M7588 4274 c-5 -4 -8 -207 -8 -451 l0 -443 136 0 c186 0 237 18 278
98 22 43 31 172 17 247 -9 50 -18 66 -50 97 l-39 37 22 15 c40 26 56 76 56
174 0 98 -9 134 -43 170 -44 48 -74 56 -222 60 -77 2 -143 1 -147 -4z m247
-149 c22 -21 25 -33 25 -93 0 -91 -16 -113 -88 -120 l-52 -5 0 115 c0 63 3
118 7 121 3 4 24 7 45 7 28 0 46 -7 63 -25z m12 -365 c39 -35 43 -199 7 -235
-10 -10 -35 -15 -75 -15 l-59 0 0 135 0 135 53 0 c39 0 59 -5 74 -20z"/>
<path d="M8357 4274 c-4 -4 -7 -174 -7 -378 1 -389 4 -421 47 -468 35 -39 92
-60 163 -60 89 0 139 25 177 89 l28 48 0 385 0 385 -65 0 -65 0 -5 -371 c-6
-412 -4 -404 -70 -404 -66 0 -64 -8 -70 404 l-5 371 -60 3 c-34 2 -64 0 -68
-4z"/>
<path d="M9857 4274 c-4 -4 -7 -207 -7 -451 l0 -443 73 0 72 0 0 197 0 198 78
3 78 3 -1 -198 -2 -198 71 -3 71 -3 -2 448 -3 448 -67 3 -68 3 -2 -183 -3
-183 -75 0 -75 0 -2 180 -2 180 -63 3 c-35 2 -67 0 -71 -4z"/>
<path d="M10647 4274 c-4 -4 -7 -207 -7 -451 l1 -443 74 0 75 0 -2 448 -3 447
-65 3 c-36 2 -69 0 -73 -4z"/>
<path d="M8080 3400 c-75 -75 -180 -852 -126 -935 18 -27 59 -34 73 -12 4 6
10 35 13 62 l5 50 47 5 c96 11 147 100 156 266 5 100 4 103 -21 128 -31 31
-64 33 -117 6 -28 -15 -40 -17 -40 -8 0 25 40 245 61 335 12 51 19 99 15 108
-8 21 -42 19 -66 -5z m80 -547 c0 -73 -39 -183 -75 -213 -58 -48 -30 160 30
224 33 36 45 33 45 -11z"/>
<path d="M4768 3383 c-9 -10 -33 -96 -54 -190 -36 -164 -39 -173 -62 -173 -39
0 -78 -27 -111 -77 -41 -61 -62 -130 -68 -224 -5 -70 -4 -77 20 -104 21 -25
33 -30 73 -30 l48 -1 0 -53 c1 -54 19 -81 55 -81 37 1 41 16 56 196 16 199 19
215 71 469 49 239 51 255 28 272 -24 17 -38 16 -56 -4z m-143 -621 c-6 -42
-47 -103 -60 -90 -12 11 14 135 38 185 l22 46 3 -49 c2 -27 1 -68 -3 -92z"/>
<path d="M6887 3306 c-25 -25 -60 -188 -113 -514 -37 -230 -37 -233 -18 -252
22 -22 64 -34 79 -23 6 5 21 41 33 80 20 64 62 133 80 133 4 0 6 -28 4 -62 -6
-108 31 -156 98 -128 35 14 106 90 158 167 29 44 33 55 22 68 -21 26 -47 17
-81 -27 -18 -24 -46 -58 -62 -77 l-30 -35 7 76 c8 81 -5 129 -39 142 -33 12
-83 6 -109 -15 l-26 -20 0 29 c0 28 33 210 64 357 19 88 14 115 -24 115 -16 0
-35 -7 -43 -14z"/>
<path d="M5551 3213 c-9 -22 -31 -122 -31 -139 0 -2 -35 -4 -78 -4 -83 0 -128
-17 -106 -40 17 -17 79 -29 123 -23 21 3 41 1 45 -5 3 -6 2 -41 -4 -79 -43
-307 -27 -494 46 -533 12 -7 30 -10 40 -6 14 5 15 15 10 54 -15 106 -16 236
-4 397 l13 170 85 5 c69 4 85 8 88 22 4 20 -22 29 -103 37 l-60 6 5 45 c9 107
8 115 -26 118 -26 3 -33 -2 -43 -25z"/>
<path d="M7401 2899 c-45 -48 -64 -168 -41 -251 16 -57 69 -93 137 -92 46 0
57 4 87 33 18 18 42 50 51 72 18 39 18 39 77 39 98 0 162 40 125 77 -15 15
-19 15 -55 -1 -64 -29 -76 -21 -82 52 -6 70 -23 92 -70 92 -32 0 -50 -17 -50
-47 0 -28 -32 -127 -62 -190 -23 -48 -45 -64 -59 -42 -14 22 -11 111 6 165 20
66 19 91 -4 104 -28 14 -38 13 -60 -11z"/>
<path d="M5044 2890 c-92 -59 -117 -242 -42 -305 40 -34 90 -34 124 0 23 23
27 24 47 10 53 -37 100 -8 179 112 39 60 40 64 24 82 -26 29 -47 16 -95 -60
-44 -70 -60 -83 -63 -51 -2 26 -2 32 6 100 7 62 7 63 -27 97 -29 29 -42 35
-78 35 -27 0 -56 -8 -75 -20z m92 -54 c8 -21 -24 -125 -48 -158 -27 -39 -48
-36 -48 6 0 44 17 94 47 134 26 35 41 40 49 18z"/>
<path d="M5920 2893 c-65 -45 -94 -114 -88 -209 4 -60 7 -70 36 -95 41 -37 89
-39 129 -5 25 21 31 22 48 10 10 -8 32 -14 48 -14 34 0 84 50 136 136 28 44
31 57 21 69 -25 31 -49 17 -96 -55 -25 -38 -50 -70 -56 -70 -8 0 -9 26 -5 86
4 51 2 94 -4 108 -24 52 -119 74 -169 39z m88 -75 c4 -34 -33 -130 -62 -157
l-24 -22 -6 27 c-8 31 0 76 21 119 34 66 65 81 71 33z"/>
<path d="M6807 1042 c-12 -2 -17 -11 -15 -25 4 -31 52 -31 56 -1 3 23 -12 33
-41 26z"/>
<path d="M4850 912 l0 -119 65 -1 c85 -1 120 24 120 87 0 58 -31 86 -90 85
-45 -1 -45 -1 -45 33 0 29 -3 33 -25 33 l-25 0 0 -118z m128 -29 c4 -40 -20
-60 -53 -45 -17 8 -25 20 -25 36 0 60 72 69 78 9z"/>
<path d="M5270 910 l0 -120 25 0 25 0 0 120 0 120 -25 0 -25 0 0 -120z"/>
<path d="M5370 909 c0 -119 0 -120 23 -117 21 3 22 7 25 121 l3 117 -26 0 -25
0 0 -121z"/>
<path d="M5670 910 l0 -120 25 0 25 0 0 120 0 120 -25 0 -25 0 0 -120z"/>
<path d="M5965 1018 c-3 -8 -4 -61 -3 -119 l3 -104 68 -2 c61 -2 72 1 93 22
30 30 33 92 5 126 -15 19 -29 24 -65 24 -43 0 -45 2 -48 30 -2 22 -9 31 -26
33 -12 2 -24 -3 -27 -10z m130 -119 c9 -27 -3 -56 -24 -62 -29 -7 -51 11 -51
43 0 47 61 63 75 19z"/>
<path d="M6572 913 c3 -114 4 -118 26 -121 20 -3 22 1 22 37 0 64 15 93 48 89
25 -3 27 -6 32 -63 4 -53 8 -60 28 -63 21 -3 22 0 22 65 -1 90 -14 109 -80
106 l-50 -1 0 34 c0 30 -3 34 -25 34 l-26 0 3 -117z"/>
<path d="M7019 1015 c-1 -8 -3 -19 -4 -25 -1 -5 -3 -13 -4 -17 0 -5 -22 -8
-47 -8 -36 0 -50 -5 -65 -24 -25 -30 -25 -92 0 -122 16 -21 28 -23 92 -24 l74
0 3 118 3 117 -26 0 c-15 0 -25 -6 -26 -15z m-8 -117 c8 -14 6 -25 -6 -44 -18
-28 -37 -31 -59 -8 -22 22 -20 50 5 68 25 17 45 12 60 -16z"/>
<path d="M7316 1002 c-3 -3 -6 -14 -6 -24 0 -10 -4 -18 -10 -18 -5 0 -10 -9
-10 -20 0 -11 4 -20 9 -20 5 0 11 -23 13 -52 6 -59 27 -81 72 -76 38 4 45 25
12 38 -14 5 -27 17 -28 27 -4 53 -2 58 20 61 28 4 31 42 3 42 -10 0 -21 8 -24
18 -4 19 -40 35 -51 24z"/>
<path d="M3870 958 c0 -7 12 -48 27 -90 24 -67 31 -78 51 -78 22 0 27 6 57 85
6 17 10 11 25 -30 15 -44 20 -50 45 -50 25 0 30 6 53 73 14 39 28 72 31 72 4
0 18 -33 32 -72 22 -63 30 -73 51 -76 23 -3 28 3 43 48 9 28 20 48 23 45 4 -3
14 -25 22 -48 12 -34 20 -42 40 -42 21 0 28 10 51 70 14 39 30 69 35 68 5 -1
18 -28 30 -61 11 -32 24 -64 28 -71 13 -20 46 -12 56 14 6 14 15 37 21 53 l11
27 21 -53 c17 -46 23 -53 45 -50 21 3 29 15 53 83 15 44 24 84 20 88 -10 10
-41 -2 -42 -17 0 -6 -8 -29 -18 -51 l-18 -40 -17 52 c-27 82 -58 81 -87 -5
l-16 -47 -17 39 c-9 21 -16 44 -16 51 0 16 -22 25 -60 25 -33 0 -39 -7 -61
-70 l-16 -45 -17 39 c-9 21 -16 42 -16 46 0 28 -59 34 -61 6 0 -6 -8 -29 -18
-51 l-18 -40 -17 53 c-13 39 -23 53 -42 58 -48 12 -71 -3 -89 -56 -9 -27 -18
-50 -20 -50 -2 0 -11 22 -20 49 -25 77 -61 78 -84 2 -16 -55 -26 -57 -41 -8
-14 49 -27 67 -46 67 -8 0 -14 -6 -14 -12z"/>
<path d="M5099 955 c-67 -37 -51 -141 24 -159 44 -11 92 2 92 24 0 11 -11 15
-40 15 -22 0 -47 5 -55 10 -11 7 2 11 50 15 65 5 65 5 63 34 -4 59 -79 93
-134 61z m81 -40 c15 -18 15 -17 -32 -20 -40 -2 -46 3 -26 23 16 16 43 15 58
-3z"/>
<path d="M5510 963 c-66 -26 -82 -103 -29 -147 43 -36 91 -35 130 3 36 36 38
79 7 116 -23 26 -78 40 -108 28z m78 -81 c2 -13 -6 -27 -23 -38 -24 -16 -27
-16 -45 1 -26 24 -25 50 2 69 19 13 25 14 43 2 11 -7 21 -23 23 -34z"/>
<path d="M5792 962 c-13 -4 -23 -10 -23 -14 1 -5 1 -13 1 -19 0 -7 11 -9 31
-5 31 6 74 -9 65 -22 -3 -4 -22 -9 -42 -10 -48 -4 -64 -17 -64 -53 0 -37 21
-47 98 -46 l62 0 0 67 c0 53 -4 71 -19 84 -21 19 -75 28 -109 18z m74 -121
c-4 -6 -18 -11 -32 -11 -31 0 -32 27 -1 32 26 5 42 -6 33 -21z"/>
<path d="M6191 961 c-11 -7 -13 -24 -8 -72 7 -84 24 -98 113 -97 l65 1 -3 86
c-3 78 -5 86 -23 86 -17 0 -20 -8 -23 -50 -4 -57 -15 -75 -48 -75 -23 0 -24 4
-24 59 0 44 -4 60 -16 65 -19 7 -16 8 -33 -3z"/>
<path d="M6423 960 c-29 -12 -41 -56 -20 -77 8 -8 30 -18 48 -22 18 -3 34 -12
37 -20 3 -10 -7 -12 -44 -9 -38 2 -49 0 -52 -13 -6 -30 88 -40 126 -13 24 17
29 56 10 72 -7 6 -28 14 -46 19 -43 11 -36 28 11 26 30 -1 37 2 37 16 0 28
-60 40 -107 21z"/>
<path d="M6795 957 c-3 -7 -5 -47 -3 -88 3 -68 5 -74 26 -77 22 -3 23 -2 20
85 -3 74 -6 88 -21 91 -9 2 -20 -3 -22 -11z"/>
<path d="M7132 960 c-33 -14 -22 -35 19 -36 53 -1 59 -3 59 -18 0 -11 -14 -16
-47 -18 -42 -3 -49 -6 -58 -32 -15 -43 18 -65 95 -64 l60 1 1 36 c3 65 -2 106
-15 119 -17 18 -85 24 -114 12z m76 -112 c-7 -20 -58 -23 -58 -4 0 11 10 16
31 16 20 0 29 -4 27 -12z"/>
<path d="M7473 963 c-15 -3 -23 -12 -23 -25 0 -18 4 -20 31 -14 31 6 74 -9 65
-22 -3 -4 -22 -9 -42 -10 -48 -4 -64 -17 -64 -53 0 -37 21 -47 98 -46 l62 0 0
66 c0 75 -5 86 -43 100 -29 11 -44 12 -84 4z m75 -115 c-7 -20 -58 -23 -58 -4
0 11 10 16 31 16 20 0 29 -4 27 -12z"/>
<path d="M7760 957 c-51 -26 -62 -104 -21 -141 38 -35 141 -28 141 9 0 21 -24
29 -49 15 -31 -16 -61 3 -61 40 0 38 28 55 65 38 20 -9 28 -9 38 1 9 10 7 16
-12 32 -27 22 -65 24 -101 6z"/>
<path d="M7954 961 c-66 -28 -71 -131 -7 -160 65 -30 143 13 143 78 0 61 -77
107 -136 82z m84 -79 c2 -13 -6 -27 -23 -38 -24 -16 -27 -16 -45 1 -26 24 -25
50 2 69 19 13 25 14 43 2 11 -7 21 -23 23 -34z"/>
<path d="M8130 960 c-7 -4 -10 -38 -8 -86 3 -73 5 -79 25 -82 20 -3 22 1 25
54 3 60 20 83 52 71 12 -5 16 -20 16 -67 0 -57 1 -60 25 -60 23 0 25 3 25 48
0 55 18 86 46 80 16 -3 20 -14 24 -63 4 -53 8 -60 28 -63 21 -3 22 0 22 67 0
59 -4 75 -21 92 -17 17 -28 20 -58 14 -20 -4 -43 -7 -51 -6 -8 0 -43 2 -77 4
-35 2 -67 0 -73 -3z"/>
<path d="M8544 955 c-3 -7 -4 -45 -2 -86 3 -68 5 -74 25 -77 20 -3 22 1 25 54
2 41 8 60 21 68 32 20 52 -4 55 -68 3 -52 5 -56 27 -56 24 0 25 3 25 64 0 92
-17 111 -105 112 -43 0 -68 -3 -71 -11z"/>
<path d="M8794 958 c-22 -10 -44 -47 -44 -73 0 -52 55 -93 110 -81 32 6 39 -4
15 -24 -10 -8 -30 -10 -59 -6 -34 5 -45 4 -49 -8 -9 -22 23 -36 78 -36 36 0
51 6 71 25 23 23 25 30 22 116 l-3 92 -60 2 c-33 2 -70 -2 -81 -7z m77 -38
c24 -14 25 -56 1 -69 -49 -26 -94 27 -52 61 26 21 26 21 51 8z"/>
<path d="M4754 835 c-4 -9 0 -23 9 -32 21 -21 49 -9 45 19 -4 28 -45 38 -54
13z"/>
<path d="M7636 834 c-7 -19 10 -44 30 -44 16 0 33 30 27 48 -7 18 -50 15 -57
-4z"/>
<path d="M8450 825 c0 -31 26 -43 47 -22 21 21 9 47 -22 47 -20 0 -25 -5 -25
-25z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
